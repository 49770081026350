<template>
    <b-card no-body class="shadow-lg rounded px-0 m-0" border-variant="light" bg-variant="primary">
      <!-- <div slot="header">
        <strong>Device Form</strong>
        <b-button class=" pull-right" @click="$router.go(-1)" size="sm" variant="primary"><i class="fa fa-check-circle-o" aria-hidden="true"></i> Back</b-button>
      </div> -->
      <b-card-body class="my-0 pb-0">
      <b-col sm="6">
        <b-form-group>
          <b-input-group size="sm">
            <b-form-input
              ref="focus"
              v-model="context"
              type="search"
              id="filterInput"
              placeholder="Search on company code"
              @keyup.enter="onfilterContext"
            ></b-form-input>
          </b-input-group>
        </b-form-group>  
      </b-col>
    </b-card-body>
  </b-card>
</template>
<script>
export default {
  name: 'ClientFilter',
  data () {
      return {
        context: null,
        }     
  },
  methods: {
    onfilterContext(){
      this.$emit('context', this.context)
    }
  },
}
</script> 