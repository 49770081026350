<template>
	<div class="shadow-lg rounded">
		<b-overlay id="overlay-background" :show="isBusy" variant="secondary" opacity="0.8" blur="2px" rounded="sm">
			<b-table ref="table" responsive small striped show-empty :fields="fields" :items="provider" :busy.sync="isBusy" :current-page="currentPage" :per-page="perPage" :sort-by.sync="sortedBy" :sort-desc.sync="sortedDesc" :no-border-collapse="false" head-variant="light">
				<template v-slot:cell(total_devices)="data">
					{{ data.item.total_devices > 0 ? data.item.total_devices : "None" }}
				</template>
				<template v-slot:cell(actions)="row">
					<b-button-group >
						<b-button :disabled="row.item.device_set.length < 1" :variant="row.item.device_set.length < 1 ? 'secondary' : 'primary'" @click="row.toggleDetails" v-b-tooltip.hover :title="row.item.device_set.length < 1 ? 'No Devices' : 'Expand Company'"> <i class="fa fa-ellipsis-h"></i></b-button>
						<b-button variant="primary" :to="`dashboard/company/${row.item.id}/details`" v-b-tooltip.hover title="Edit Company"><i class="fa fa-pencil"></i></b-button>
						<b-button variant="primary" :to="`dashboard/company/${row.item.id}/devices`" v-b-tooltip.hover title="Manage Devices"><i class="fa fa-cubes"></i></b-button>
					</b-button-group>
				</template>
				<template v-slot:row-details="row">
					<template v-if="row.item.device_set.length > 0">
						<b-card border-variant="primary" class="m-0 pt-2">
							<MedsTable :fields="detailed_fields" :items="row.item.device_set" :customSlots="slots" class="px-0 mx-0">
								<!-- latest device data -->
								<template v-slot:slot-temp1="data">
									{{ data.data.item.timestamp_set.length > 0 ? data.data.item.timestamp_set[0].measurement.temperature1 : "No Data" }}
								</template>
								<template v-slot:slot-temp2="data">
									{{ data.data.item.timestamp_set.length > 0 ? data.data.item.timestamp_set[0].measurement.temperature2 : "No Data" }}
								</template>
								<template v-slot:slot-temp3="data">
									{{ data.data.item.timestamp_set.length > 0 ? data.data.item.timestamp_set[0].measurement.temperature3 : "No Data" }}
								</template>
								<template v-slot:slot-timestamp="data">
									{{ data.data.item.timestamp_set.length > 0 ? formattedShortDateTime(data.data.item.timestamp_set[0].insert) : "No Data" }}
								</template>
								<template v-slot:slot-lock="data">
									{{ data.data.item.timestamp_set.length > 0 ? formatBinaryStatusValues("lock", data.data.item.timestamp_set[0].measurement.lock) : "No Data" }}
								</template>
								<template v-slot:slot-location="data">
									<!-- {{combineLocation(data.data.item.timestamp_set)}} -->
									{{ data.data.item.timestamp_set.length > 0 ? combineLocation(data.data.item.timestamp_set[0].location) : "No Data" }}
								</template>
								<template v-slot:slot-orientation="data">
									{{ data.data.item.timestamp_set.length > 0 ? formatBinaryStatusValues("orientation", data.data.item.timestamp_set[0].measurement.orientation) : "No Data" }}
								</template>
								<template v-slot:actions="{ row }">
									<b-button-group size="sm">
										<b-button :disabled="!access_button" size="sm" variant="primary" @click="onClickCharts(row)" class="p-2"> <i class="fa fa-bar-chart"></i></b-button>
										<b-button size="sm" variant="primary" @click="onClickEditDevice(row)" ><i class="fa fa-pencil"></i></b-button>
										<b-button size="sm" variant="primary" @click="onBatteryCharged(row)" > <i class="fa fa-battery"></i></b-button>
									</b-button-group>
								</template>
							</MedsTable>
						</b-card>
					</template>
					<template v-else>
						<div class="p-3 bg-light">No devices linked with {{ row.item.name }}</div>
					</template>
				</template>
				<template v-slot:custom-foot="scope">
					<b-tr class="border">
						<b-td colspan="1" class="align-middle pl-2" variant="secondary"> Records {{ startIndex }} tot {{ endIndex }} van {{ totalRows }} </b-td>
						<b-td colspan="3" class="align-middle" variant="secondary">
							<b-pagination v-if="showPagination" class="m-0" prev-text="Prev" next-text="Next" hide-goto-end-buttons align="middle" :total-rows="totalRows" v-model="currentPage" :per-page="perPage" />
						</b-td>
					</b-tr>
				</template>
			</b-table>
		</b-overlay>
	</div>
</template>

<script>
import MedsTable from "../../components/MedsTable"
import { mapGetters, mapState, mapActions } from "vuex"
import ViewExtension from "../ViewExtension"

export default ViewExtension.extend({
	name: "ClientTable",
	props: ["context"],
	components: {
		MedsTable,
	},
	data() {
		return {
			fields: [
				{ key: "name", label: "Company" },
				{ key: "code", label: "Code" },
				{ key: "total_devices", label: "Total Devices" },
				{ key: "actions", label: "Actions" },
			],
			detailed_fields: [
				{ key: "name", label: "Device" },
				{ key: "uuid", label: "ID" },
				{ key: "fw", label: "Firmware" },
				{ key: "type", label: "Type" },
				{ key: "timestamp", label: "Latest acquired data" },
				{ key: "temp1", label: "Temp1" },
				{ key: "temp2", label: "Temp2" },
				{ key: "temp3", label: "Temp3" },
				{ key: "lock", label: "Lock" },
				{ key: "location", label: "Long/Lang" },
				{ key: "orientation", label: "Orientation" },
				{ key: "alarm", label: "Alarm/ Last Alarm" },
				{ key: "next_charge", label: "Charge in (days)" },
				{ key: "actions", label: "Actions" },
			],
			slots: [{ key: "temp1" }, { key: "temp2" }, { key: "temp3" }, { key: "lock" }, { key: "location" }, { key: "orientation" }, { key: "timestamp" }, { key: "total_devices" }],

			currentPage: 1,
			perPage: 10,
			sortedBy: "id",
			sortedDesc: true,
			totalRows: 0,
		}
	},
	computed: {
		...mapState("auth", {
			current: (state) => state.current,
		}),
		...mapState("common", {
	      access_button: state => state.access_button
	    }),
		startIndex() {
			return (this.currentPage - 1) * this.perPage + 1
		},
		endIndex() {
			var end = this.totalRows
			if (this.perPage < this.totalRows) {
				end = this.perPage * this.currentPage
				if (end > this.totalRows) {
					end = this.totalRows
				}
			}
			return end
		},
		showPagination() {
			return this.perPage < this.totalRows
		},
	},
	watch: {
		context(val) {
			this.filterContext()
		},
	},
	methods: {
		...mapActions("meds2go/client", ["clientDevices"]),
		...mapActions("meds2go/company", ["filterCompanies"]),
		...mapActions("meds2go/device", ["devicePartial"]),
		// ...mapActions("common", ["logGrafanaAccess"]),
		provider(ctx, callback) {
			// this.loading()
			this.filterCompanies({
				context: this.context,
				page: ctx.currentPage,
				per_page: ctx.perPage,
				sort_by: ctx.sortBy,
				sort_desc: ctx.sortDesc,
			}).then((results) => {
				callback(results.items)
				this.totalRows = results.tot_rows
			})
			// this.stopLoading()
		},
		onClickCharts(row) {
			if (row.item.hasOwnProperty("id")) {
				this.$router.push(`/dashboard/client/charts/${row.item.id}`)
			}
		},
		onClickEditDevice(row) {
			if (row.item.hasOwnProperty("id")) {
				this.$router.push(`/dashboard/device/${row.item.id}/modify`)
			}
		},
		onBatteryCharged(row) {
			let now = new Date()
			this.devicePartial({ id: row.item.id, consistency_token: row.item.consistency_token, battery_charged_on: now }).then((results) => {
				this.$refs.table.refresh()
			})
		},
		filterContext() {
			this.$refs.table.refresh()
		},
		combineLocation(data) {
			let long = data.longitude
			let lat = data.latitude
			return long.toString() + "/" + lat.toString()
		},
	},
})
</script>
<style type="text/css">
b-tr {
	.no-hover {
		background-color: #ffffff;
	}
}
</style>
