<template>
  <div class="animated fadeIn">
    <ClientFilter @context="onContext"/>
    <ClientTable :context="context"/>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex"
import ClientFilter from './admin/client/ClientFilter'
import ClientTable from './admin/ClientTable'
import ViewExtension from './ViewExtension'
import moment from 'moment'

export default ViewExtension.extend({
  name: 'Dashboard',
  components: {
    ClientTable,
    ClientFilter,
  },
  data() {
  	return {
  		context: null,
  	}
  },
  methods: {
  	onContext(context){
  		this.context = context
  	},
  }
})
</script>
